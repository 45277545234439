<template>
    <div>
        <div class="search">
            <el-form ref="pageInfo" :model="pageInfo" label-width="120px">
                <!-- <el-row :gutter="24"> -->
                    <!-- <el-col :span="4"> -->
                        <el-form-item label="买家账号：">
                            <el-input v-model="pageInfo.userName" placeholder="请输入"></el-input>
                        </el-form-item>
                    <!-- </el-col> -->
                    <!-- <el-col :span="4"> -->
                        <el-form-item label="提交时间：">
                            <el-date-picker
                                key="day"
                                v-model="pageInfo.submitTime"
                                style="width: 186px;"
                                type="date"
                                value-format="yyyy-MM-dd" 
                                format="yyyy-MM-dd"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    <!-- </el-col> -->
                    <!-- <el-col :span="4">
                        <el-form-item label="商家类型：">
                            <el-input  v-model="pageInfo.userName"></el-input>
                        </el-form-item>
                    </el-col> -->
                    <!-- <el-col :span="4"> -->
                        <!-- <el-form-item label="审核状态：">
                            <el-input  v-model="pageInfo.companyName" placeholder="请输入"></el-input>
                        </el-form-item> -->
                    <!-- </el-col> -->

                    <!-- <el-col :span="4" style="text-align: center"> -->
                        <div style="margin-bottom: 22px; display: inline-block; vertical-align: middle; padding-left: 30px">
                            <el-button type="primary" @click="searchHandle" icon="el-icon-search">查询</el-button>
                            <a class="clear cp" @click="clearSearch">清空</a>
                        </div>
                        
                    <!-- </el-col> -->

                <!-- </el-row> -->
            </el-form>
        </div>
        <div style="height: 20px; background: #F6F7F9;"></div>
        <div class="content">
            <h4>企业认证列表</h4>
            <el-tabs v-model="activeName" @tab-click="handleClickTab">
                <el-tab-pane label="全部" name="0"></el-tab-pane>
                <el-tab-pane label="待审核" name="1"></el-tab-pane>
                <el-tab-pane label="审核拒绝" name="2"></el-tab-pane>
                <el-tab-pane label="审核通过" name="3"></el-tab-pane>
            </el-tabs>

            <div style="padding: 0 24px">
                <el-table border :data="tableData" style="width: 100%">
                    <el-table-column
                        fixed="left"
                                type="selection"
                                width="55">
                        </el-table-column>
                        <el-table-column prop="userName" min-width="140" label="买家账号"></el-table-column>
                        <el-table-column prop="userChineseName" min-width="120" label="昵称">
                        
                        </el-table-column>
                        <el-table-column prop="userChineseName" min-width="120" label="真实姓名"></el-table-column>
                        <el-table-column prop="submitTime" min-width="120" label="提交时间"></el-table-column>
                        <el-table-column prop="spuLowPrice" min-width="80" label="审核状态">
                            <template #default="scope">{{ statuss(scope.row.status) }}</template>
                        </el-table-column>
                        
                        <el-table-column prop="name" width="140" fixed="right" label="操作">
                            <template slot-scope="scope">
                                <el-button  @click="examineDetails(scope.row)" type="text" size="small">查看</el-button>
                                <el-button v-if="scope.row.spuStatus == 1" type="text" @click="updateSpuStatus(scope.row.id,1)" size="small">通过</el-button>
                                <el-button v-if="scope.row.spuStatus == 1" type="text" @click="updateSpuStatus(scope.row.id,1)" size="small">拒绝</el-button>
                                <!-- <el-button v-if="scope.row.spuStatus == 2 || scope.row.spuStatus == 0" type="text" @click="updateSpuStatus(scope.row.id,2)" size="small">上架</el-button> -->
                            </template>
                        </el-table-column>
                </el-table>
            </div>

            <div style="text-align: right; padding-right: 20px">
                <div style="margin-top: 12px; display: inline-block">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageInfo.pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageInfo.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageInfo.total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog title="审批意见" width="700px" :visible.sync="dialogdFormVisible">
            <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="approveReason"
                show-word-limit
                >
                </el-input>

            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button @click="attrDialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="rejectClick">拒绝</el-button>
            </div>
      </el-dialog>
        <div class="zw"></div>
        <div class="footer"></div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            id: '',// 记录id
            dialogdFormVisible: false,
            activeName: '',
            tableData: [],
            pageInfo: {
                status: '',
                submitTime: '',
                userName: '',
                pageNo: 1,
                pageSize: 10,
                total: 0,
                
            },
            approveReason: '',
            commodityCategoryList: [], //平台类目
            categoryQueryForList: [] //类目list
        }
    },
    mounted(){
        this.search()
    },
    created() {
        this.commodityCategoryQueryForList()
    },
    methods:{
        search(){
            // let params = {
            //     pageNo: this.pageInfo.pageNo,
            //     pageSize: this.pageInfo.pageSize,
            // };
            this.qa.queryForOperateApprovePageList(this.pageInfo).then(res => {
                if(res.respCode == "0000") {
                    this.tableData = res.data;
                    this.pageInfo.total = res.total;
                } else {
                    this.$message.error(res.msg);
                }
            })
            this.qa.queryAllTreeCommodityCategoryPOs({}).then(res => {
                if(res.respCode == "0000"){
                    
                    var arr = res.data
                   //this.commodityCategoryList = res.data
                   for (var i = 0; i<arr.length; i++) {
                       if(arr[i].children.length > 0) {
                           for (var j = 0; j<arr[i].children.length; j++) {
                               if(arr[i].children[j].children.length > 0) {
                                   for (var z=0; z<arr[i].children[j].children.length; z++) {
                                       if(arr[i].children[j].children[z].children.length == 0) {
                                           delete arr[i].children[j].children[z].children
                                       }
                                   }
                               }
                           }
                       }
                   }
                   this.commodityCategoryList = arr
                } else {
                    this.$message.error("查询类目失败，请联系管理员！");
                }
            })
        },
        commodityCategoryQueryForList(){ //类目list
            this.qa.commodityCategoryQueryForList({}).then(res => {
                if(res.respCode == "0000") {
                    this.categoryQueryForList = res.data;
                }
            })
        },
        searchHandle() {
            this.search()
        },
        handleClickTab(e,event) {
            if( this.activeName == 0) {
                this.pageInfo.status = ''
            }else {
                this.pageInfo.status = this.activeName
            }
            this.search()
        },
        handleSizeChange(page){
          this.pageInfo.pageSize = page;
          this.search()
        },
        handleCurrentChange(page) {
            this.pageInfo.pageNo = page;
            this.search()
        },
        clearSearch() {
            this.pageInfo = {
                status: '',
                submitTime: '',
                userName: '',
                pageNo: 1,
                pageSize: 10,
                total: 0,
                
            };
            this.activeName = "";
            this.search()
        },
        //查看详情
        examineDetails(row) {
            this.$router.push({
                path: '/companyDetail',
                query: {
                    shopId: row.shopId,
                    id: row.id,
                }
            });
        },
        reject(row) {
            this.dialogdFormVisible = true
            this.id = row.id
        },
        rejectClick() {
            if(this.approveReason == '' ) {
                this.$message.error('请输入审批原因');
                return
            }
            var data = {
                status: 2,
                approveReason:  this.approveReason,
                id: this.id
            }
            this.qa.doApproveById(data).then(res => {
                if(res.respCode == "0000") {
                    this.$message({
                        message: '已拒绝！',
                        type: 'success'
                    });
                    this.dialogdFormVisible = false
                    this.search()
                } else {
                    this.$message.error(res.msg);
                }
            })
        }, 
        pass(row) {
            var data = {
                status: 3,
                approveReason:  this.approveReason,
                id: row.id
            }
            this.qa.doApproveById(data).then(res => {
                if(res.respCode == "0000") {
                    this.$message({
                        message: '已通过！',
                        type: 'success'
                    });
                    this.dialogdFormVisible = false
                    this.search()
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        spuStatusFormatter(row){
            let spuStatus = row.spuStatus;
            if(spuStatus === '1'){
                return '上架'
            }else if(spuStatus === '2'){
                return '下架'
            }else if(spuStatus === '0'){
                return '草稿（待上架）'
            }
        },
        categoryFormatter(row){
            let category3Id = row.category3Id;
            let categoryName = ''
            if(this.categoryQueryForList.length > 0){
                this.categoryQueryForList.forEach(element => {
                    if(element.id === category3Id){
                        categoryName = element.categoryName
                        return 
                    }
                });
                return categoryName
            }else{
                return category3Id
            }
        },
        statuss(id){
            if(id == 0) {
                return '草稿'
            } else if(id == 1) {
                return '待审核'
            } else if(id == 2) {
                return '审核拒绝'
            } else if(id == 3) {
                return '审核通过'
            }
        },
        updateSpuStatus(id,type){
            if(type === 1){
                this.$confirm('是否确认下架商品?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                    this.qa.doUpdateCommodityShelfTime({id: id, spuStatus: 2}).then(res => {
                        this.$message({
                        message: '下架成功',
                        type: 'success'
                        });
                        this.search();
                    })
                    }).catch(() => {        
                });
            }else{

                this.$confirm('是否确认上架商品?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                    this.qa.doUpdateCommodityShelfTime({id: id, spuStatus: 1}).then(res => {
                        this.$message({
                        message: '上架成功',
                        type: 'success'
                        });
                        this.search();
                    })
                    }).catch(() => {        
                });
            }
        }
    }
}
</script>

<style scoped>
  .search {
      background: #fff;
      padding: 24px;
      padding-bottom: 0;
      
  }
  .search >>> .el-form .el-form-item {
      display: inline-block;
      vertical-align: middle;
  }
  .search >>> .el-form .el-input__inner {
      height: 34px;
  }
  .search >>> .el-button {
      padding: 9px 20px;
  }
  .search >>> .el-col {
      height: 34px;
  }
  .search >>> .el-form-item__label {
      line-height: 34px;
  }
  .search >>> .el-form-item__content {
      line-height: 34px;
  }
  .search >>> .el-form-item__content .el-cascader {
      line-height: 34px;
  }
  .content >>> .el-tabs__nav-wrap {
      padding: 0 28px;
  }
  .content >>> .el-tabs .is-active {
      background: rgba(25, 145, 252, 0.1);
  }
  .content >>> .el-tabs .el-tabs__item {
      /* padding: 0; */
      

  }
  .content >>> .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 20px;
  }
  .content >>> .el-tabs--top .el-tabs__item.is-top:last-child {
      padding-right: 20px;
  }
  .clear {
      font-size: 14px;
      color: #8C9099;
      margin-left: 24px;
  }
  .content {
      /* padding: 24px; */
      padding: 24px 0 24px;
      background: #fff;
      /* margin-top: 14px; */
  }
  .content >h4 {
      padding: 0 24px;
      margin-bottom: 20px;
  }

  .wRound {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 1px solid #F53333;
  }
  .nRound {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #F53333;
  }
  
    .logo {
        width: 100px;        
    }
</style>